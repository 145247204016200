import { Node } from "../model/database";

/**
 * ふたつのノードを `Node#treeIndex` 順になるよう比較します。
 *
 * `Node#treeIndex` の値が等しい場合は `Node#name` の辞書順になります。
 *
 * @param a 比較対象1
 * @param b 比較対象2
 * @returns 比較結果
 */
export function compareNodesByIndex(a: Node, b: Node): number {
    // `treeIndex` 順にする
    // ※1 親ノードが異なる場合は `treeIndex` に意味が無いので無視
    // ※2 null は末尾
    if (a.parentId === b.parentId) {
        const ia = a.treeIndex ?? Number.MAX_SAFE_INTEGER;
        const ib = b.treeIndex ?? Number.MAX_SAFE_INTEGER;
        if (ia !== ib) {
            return ia - ib;
        }
    }

    // フォールバックとして名前順
    return a.name.localeCompare(b.name);
}

/**
 * 指定したノードがルートかどうか判定します。
 *
 * `Node#parentId` が `"null"` であるか、
 * または親ノードが存在しなかった (権限不足など) 場合にルートになります。
 *
 * @param node 対象ノード
 * @param nodeIdSet 全ノードの ID 集合
 * @returns 対象ノードがルートであれば真
 */
export function isRoot(node: Node, nodeIdSet: ReadonlySet<string>): boolean {
    return node.parentId === "null" || !nodeIdSet.has(node.parentId);
}
